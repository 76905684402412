import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import Footer from '../components/Footer';
import HeaderMain from '../components/HeaderMain';
import HeroSingle from '../components/pages/HeroSingle';
import '../styles/app.scss';

const GiftCards = ({ data }) => {
    const { wordpressPage: post } = data;

    const test_title = post.yoast_title;
    const new_seo_title = test_title.replace('&#039;', "'");

    return (
        <>
            <SEO
                title={new_seo_title}
                meta={post.yoast_meta}
                description={
                    post.yoast_json_ld[0].wordpress__graph[0].description
                }
            />
            <HeaderMain />
            <HeroSingle pageTitle={post.title} />
            <section className="page-section giftcardsection">
                <div
                    className="container"
                    dangerouslySetInnerHTML={{
                        __html: post.content,
                    }}
                />
				<iframe
                            src="https://werockthespectrummilwaukee.wrtsfranchise.com/gift-cards"
                            title="register"
                            className="healcode_widgets"
                        />
            </section>
	    <div className="popup-wrap gift_popup">
                <div className="popup">
                    <span className="close_popup">×</span>
                    <iframe src="https://werockthespectrummilwaukee.wrtsfranchise.com/giftcardregister.php" title="appointment" className="healcode_widgets" />
                </div>
            </div>

            <Footer />
        </>
    );
};

export default GiftCards;

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            title
            content
            slug
            yoast_meta {
                name
                property
            }
            yoast_title
            yoast_json_ld {
                wordpress__graph {
                    description
                }
            }
        }
    }
`;
